<template>
  <b10-confirm-dialog
    :showing.sync="internalShowing"
  >
    <template
      slot="cardText"
    >
      <div
        v-if="mensaje"
      >
        {{ mensaje }}
      </div>
    </template>
    <template
      slot="cardActions"
    >
      <v-btn
        @click.stop="clickVolver"
      >
        Volver al inicio
      </v-btn>
      <v-btn
        color="primary"
        @click.stop="clickDetener"
      >
        Detener
      </v-btn>
    </template>
  </b10-confirm-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'

export default {
  mixins: [internalShowingMixin],
  props: {
    descripcion: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
    mensaje: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      deleteOT: false,
    }
  },
  methods: {
    clickVolver () {
      this.$emit('volver', {})
    },
    clickDetener () {
      this.internalShowing = false
      this.$emit('detener', {})
    },
  },
}
</script>
